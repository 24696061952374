@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsMedium';
    src: url('./Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsBold';
    src: url('./Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/* You can define different font weights and styles similarly */
